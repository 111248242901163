import React from 'react'
import { useTranslation } from 'react-i18next'

import SEO from '../components/Seo'
import PageLayout from '../components/PageLayout'
import BannerWrapper from '../components/BannerWrapper'
import TextBlock from '../components/TextBlock'
import Banner from '../components/Banner'
import Features from '../components/Features'
import FreeTrialBanner from '../components/FreeTrialBanner'
import BackButton from '../components/BackButton'

const FeaturesPage = () => {
  const { t } = useTranslation()
  return (
    <PageLayout>
      <SEO title="Features" />
      <BannerWrapper>
        <BackButton />
        <Banner
          imageName="desk-ipad-03"
          imageAlt={t('ImageAlts:featurePageImageAlt')}
          textOrientation="left"
        >
          <TextBlock
            heading={t('Global:takeControlText')}
            textBeforeLink={t('Banners:detail1')}
          />
        </Banner>
        <Features />
      </BannerWrapper>
      <FreeTrialBanner />
    </PageLayout>
  )
}
export default FeaturesPage
